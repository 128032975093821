import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import Layout from "../../../components/layout"
import Seo from "../../../components/seo"

const InsuranceAndClaims = () => {
    return (
        <Layout>
            <Seo title="Insurance and Claims" />
            <div className="container py-3 py-lg-4">
                <div className="row g-0 mb-3">
                    <div className="col border-bottom border-1 border-dark">
                        <h1>Insurance and Claims</h1>
                    </div>
                </div>
                <div className="row">
                    <div className="card mb-3 border-0">
                        <div className="row gx-3">
                            <div className="col-lg-6">
                                <div className="card-body gray-bg d-flex flex-column">
                                    <p className="card-text">
                                        We make sure you are protected against financial risks when an adverse event occurs. Our
                                        relationship with Insurance Brokers, Clubs and industry experts, allows us to place your
                                        insurances competitively. Therefore, we have carefully chosen the best insurance companies as
                                        our partners and we manage claims in a professional, timely and cost-effective manner.
                                    </p>
                                    <h4>Hull and Machinery (H&M)</h4>
                                    <p>
                                        H&M insurances cover total and partial loss of or damage to the vessel, collision liability,
                                        general average distribution, costs of salvage and other losses caused by listed perils.
                                    </p>
                                    <h4>Protection and Indemnity (P&I)</h4>
                                    <p>
                                        P&I protects a vessel and you as shipowners and operators against third-party liabilities,
                                        including pollution, crew, cargo and stowaway liabilities. In addition to the standard
                                        coverages, we can customise coverage to your requirements.
                                    </p>
                                    <h4>War Risk Cover (WR)</h4>
                                    <p>
                                        WR insures risks excluded under the H&M, IV, and FI insurances. We believe our annual and
                                        breach premiums are among the lowest in the market.
                                    </p>
                                    <h4>Freight Demurrage and Defence (FDD)</h4>
                                    <p>
                                        FDD covers the costs and expenses incurred in connection with assured's defence of claims,
                                        disputes and proceedings.
                                    </p>
                                    <h4>Loss of Earnings Cover</h4>
                                    <p>
                                        Marine delay insurance covers delays both onboard and onshore perils. We can structure a loss
                                        of earning policy that that can help charterers, operators, traders protect their business from
                                        the financial consequences of a wide variety of delays over which they may not have any control
                                        or recourse.
                                    </p>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <StaticImage
                                    transformOptions={{ cropFocus: "attention" }}
                                    alt="Sales team"
                                    src="../../../images/other/ship1.jpg"
                                    aspectRatio={4 / 3}
                                    width={696}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default InsuranceAndClaims
